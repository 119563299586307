/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  return (
    <div className="border-4 p-5 border-sky-200 dark:border-blue-800 rounded-md md:w-3/6 ml-auto mt-4 md:mr-4">
      <div className="dark:bg-indigo-900 bg-violet-300 shadow-violet-400 shadow-md dark:shadow-indigo-600 md:flex">
        <StaticImage
          className="bio-avatar md:w-6/12 md:h-32 w-full h-auto mb-3 md:mb-0"
          // layout="s"
          formats={["auto", "png"]}
          src="../images/me/Polish_20220613_112017004.png"

          quality={95}
          alt="Profile picture"
        />

        {author?.name && (
          <p className="text-slate-800 dark:text-gray-50 md:my-auto p-4 md:text-left text-center">
            Written by <strong>{author.name}</strong> {author?.summary || null}
            {` `}
            <a href={`https://twitter.com/${social?.twitter || ``}`} className="underline font-bold text-blue-700 dark:text-sky-200 dark:decoration-zinc-200 decoration-zinc-700">
              Follow me on Twitter
            </a>
          </p>
        )}
      </div>
    </div>
  )
}

export default Bio
