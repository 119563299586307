import * as React from 'react';
import {
  Link,
  graphql,
} from 'gatsby';

import Bio from '../components/bio';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowAltCircleRight,
  faArrowAltCircleLeft,
} from '@fortawesome/free-regular-svg-icons';

const BlogPostTemplate =
  ({
    data,
    location,
  }) => {
    const post =
      data.markdownRemark;
    const siteTitle =
      data.site
        .siteMetadata
        ?.title ||
      `Title`;
    const {
      previous,
      next,
    } = data;

    return (
      <Layout
        location={
          location
        }
        title={
          post
            .frontmatter
            .title
        }>
        <Seo
          title={
            post
              .frontmatter
              .title
          }
          description={
            post
              .frontmatter
              .description ||
            post.excerpt
          }
        />

        <div className='p-4 md:px-52 dark:dark-blog-wrapper blog-wrapper blog-primary'>
          <article
            className='blog-post'
            itemScope
            itemType='http://schema.org/Article'>
            {/* <header className="text-center">
            <h1 itemProp="headline" className="dark:text-sky-100 font-semibold text-blue-600 text-4xl">{post.frontmatter.title}</h1>
            <p className="dark:text-sky-100 text-blue-600">{post.frontmatter.date}</p>
          </header> */}

            <section
              className='dark:text-sky-100 text-blue-800'
              dangerouslySetInnerHTML={{
                __html:
                  post.html,
              }}
              itemProp='articleBody'
            />
            <hr className='mt-3' />
          </article>
        </div>

        <Bio />

        <nav className='dark:text-sky-100 text-blue-800 p-4'>
          <ul
            style={{
              display: `flex`,
              flexWrap: `wrap`,
              justifyContent: `space-between`,
              listStyle: `none`,
              padding: 0,
            }}>
            <li>
              {previous && (
                <Link
                  to={
                    previous
                      .fields
                      .slug
                  }
                  rel='prev'>
                  <FontAwesomeIcon
                    icon={
                      faArrowAltCircleLeft
                    }
                  />{' '}
                  {
                    previous
                      .frontmatter
                      .title
                  }
                </Link>
              )}
            </li>
            <li>
              {next && (
                <Link
                  to={
                    next
                      .fields
                      .slug
                  }
                  rel='next'>
                  {
                    next
                      .frontmatter
                      .title
                  }{' '}
                  <FontAwesomeIcon
                    icon={
                      faArrowAltCircleRight
                    }
                  />
                </Link>
              )}
            </li>
          </ul>
        </nav>
      </Layout>
    );
  };

export default BlogPostTemplate;

export const pageQuery = graphql`
query BlogPostBySlug(
  $id: String!
  $previousPostId: String
  $nextPostId: String
) {
  site {
    siteMetadata {
      title
    }
  }
  markdownRemark(
    id: {
      eq: $id
    }
  ) {
    id
    excerpt(
      pruneLength: 160
    )
    html
    frontmatter {
      title
      date(
        formatString: "MMMM DD, YYYY"
      )
      description
    }
  }
  previous: markdownRemark(
    id: {
      eq: $previousPostId
    }
  ) {
    fields {
      slug
    }
    frontmatter {
      title
    }
  }
  next: markdownRemark(
    id: {
      eq: $nextPostId
    }
  ) {
    fields {
      slug
    }
    frontmatter {
      title
    }
  }
}
`;
